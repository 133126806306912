import { initialPreferencesAreMissing } from '../../controllers/cookie-controls/transformer';
import type {
	ConsentHubResponse,
	PrefetchedConsentCookieData,
	PrefetchedConsentPreferences,
} from '../../services/consent-hub-service/types';
import { checkIfTokenIsDefault } from '../../services/consent-hub-service/utils';
import { PrefetchedPreferenceState } from '../../types';

export const shouldShowBannerInSSRContext = (initialPreferences?: PrefetchedConsentPreferences) => {
	if (initialPreferences === PrefetchedPreferenceState.INTERNAL) {
		// If we're in the "fetch internally" state, we have no prefetched data and we can't determine
		// if the user has consented or not yet since we need to fetch, so show the banner
		return true;
	}

	if (initialPreferencesAreMissing(initialPreferences)) {
		// No data at all, we need to fetch again, but hide on this pass
		return false;
	}
	// If prefetched data has a consentToken or the consents array is there and populated
	// we want to hide the banner, as the user has has made consents already.
	const prefetchedToken = (initialPreferences as PrefetchedConsentCookieData).consentToken;
	const prefetchedTokenIsDefault = checkIfTokenIsDefault(prefetchedToken || '');
	const prefetchedConsents = (initialPreferences as ConsentHubResponse).consents;

	const hasLegitConsentToken = prefetchedToken && !prefetchedTokenIsDefault;
	const hasLegitConsents = prefetchedConsents && prefetchedConsents.length > 0;
	const prefetchedDataHasConsents = Boolean(hasLegitConsentToken || hasLegitConsents);

	const showBannerSinceUserHasNotConsented = !prefetchedDataHasConsents;
	return showBannerSinceUserHasNotConsented;
};
