import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { FlagsProvider, useFlags } from '@atlaskit/flag';
import { fg } from '@atlaskit/platform-feature-flags';
import { errorToastProps, successToastProps } from '@atlassian/eoc-common';

import { initializeGlobalState, sendTrackEvent, sendUIEvent } from '../../common/utils';
import { BSCIntlProvider } from '../../common/utils/locale';
import { Logger } from '../../common/utils/logger';
import { ACCEPT_ALL_PREFERENCES, ONLY_NECESSARY_PREFERENCES } from '../../constants';
import { updatePreferences } from '../../controllers/storage-preferences/update-preferences';
import type { ConsentDisplayedTextMap } from '../../services/consent-hub-service/types';

import { i18n } from './messages';
import { PreferenceModal } from './preference-modal';
import { PresentationalConsentBanner } from './presentational-consent-banner';
import type { ConsentBannerProps } from './types';
import useBannerVisibility from './use-banner-visibility';
import usePreferences from './use-preferences';
import usePrevious from './use-previous';

export const ConsentBanner = (props: ConsentBannerProps) => {
	// Feature flag check. ESLint rules don't allow combined flags...
	if (fg('platform.moonjelly.browser-storage-controls')) {
		return (
			<FlagsProvider>
				<ConsentBannerImpl {...props} />
			</FlagsProvider>
		);
	}
	if (fg('platform.moonjelly.browser-storage-controls-v2')) {
		return (
			<FlagsProvider>
				<ConsentBannerImpl {...props} />
			</FlagsProvider>
		);
	}

	return null;
};

const ConsentBannerImpl = ({
	onShow,
	onHide,
	initialPreferences,
	minWidth = 400,
	product,
	analyticsEnabled = true,
}: ConsentBannerProps) => {
	// This flag configuration will prevent saving prefs to ConsentHub. In this
	// case we want want to show different messaging.
	const localOnlyEnvironment =
		fg('platform.moonjelly.browser-storage-controls') &&
		!fg('platform.moonjelly.browser-storage-controls-v2');

	useEffect(() => {
		initializeGlobalState({ analyticsEnabled, product });
	}, [analyticsEnabled, product]);

	const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
	const isInitialRender = useRef(true);

	const intl = useIntl();

	const { showFlag } = useFlags();

	const { preferences } = usePreferences({
		isInitialRender,
		initialPreferences,
	});

	const { showBanner, setIsDismissed } = useBannerVisibility({
		preferences,
		initialPreferences,
		isInitialRender,
	});

	const closePreferenceModal = useCallback(() => {
		setIsPreferencesModalOpen(false);
	}, [setIsPreferencesModalOpen]);

	const onPreferenceModalSubmit = useCallback(() => {
		closePreferenceModal();
		setIsDismissed(true);
	}, [closePreferenceModal, setIsDismissed]);

	const onClickPreferences = useCallback(() => {
		setIsPreferencesModalOpen(true);

		sendUIEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'preferences',
			source: 'cookieConsentBanner',
		});
	}, []);

	const showSuccessFlag = useCallback(() => {
		if (localOnlyEnvironment) {
			showFlag({
				...successToastProps(),
				id: 'cookieConsentBanner',
				description: intl.formatMessage(i18n.successFlagDescriptionLocalOnly),
				title: intl.formatMessage(i18n.successFlagTitle),
			});
		} else {
			showFlag({
				...successToastProps(),
				id: 'cookieConsentBanner',
				description: intl.formatMessage(i18n.successFlagDescription),
				title: intl.formatMessage(i18n.successFlagTitle),
			});
		}
	}, [intl, localOnlyEnvironment, showFlag]);

	const showErrorFlag = useCallback(() => {
		showFlag({
			...errorToastProps(),
			id: 'cookieConsentBanner',
			description: intl.formatMessage(i18n.errorFlagDescription),
			title: intl.formatMessage(i18n.errorFlagTitle),
		});
	}, [intl, showFlag]);

	// Sets preferences to allow only Strictly Necessary cookies
	const onClickOnlyNecessary = useCallback(
		async (displayedTextMap: ConsentDisplayedTextMap) => {
			try {
				sendUIEvent({
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'onlyNecessary',
					source: 'cookieConsentBanner',
				});

				await updatePreferences(ONLY_NECESSARY_PREFERENCES, displayedTextMap);

				sendTrackEvent({
					source: 'cookieConsentBanner',
					action: 'submitted',
					actionSubject: 'cookieConsentPreferences',
				});

				showSuccessFlag();
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'updatePreferencesError',
					attributes: {
						preferences: ONLY_NECESSARY_PREFERENCES,
						product,
					},
					message: `Failed to update preferences from banner. ${e.message || ''}`,
				});

				showErrorFlag();
			} finally {
				setIsDismissed(true);
			}
		},
		[product, showErrorFlag, showSuccessFlag, setIsDismissed],
	);

	// Sets preferences to allow all categories of cookies
	const onClickAcceptAll = useCallback(
		async (displayedTextMap: ConsentDisplayedTextMap) => {
			try {
				sendUIEvent({
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'acceptAll',
					source: 'cookieConsentBanner',
				});

				await updatePreferences(ACCEPT_ALL_PREFERENCES, displayedTextMap);

				sendTrackEvent({
					source: 'cookieConsentBanner',
					action: 'submitted',
					actionSubject: 'cookieConsentPreferences',
				});

				showSuccessFlag();
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'updatePreferencesError',
					attributes: {
						preferences: ACCEPT_ALL_PREFERENCES,
						product,
					},
					message: `Failed to update preferences from banner. ${e.message || ''}`,
				});

				showErrorFlag();
			} finally {
				setIsDismissed(true);
			}
		},
		[product, showErrorFlag, showSuccessFlag, setIsDismissed],
	);

	const onClickTrackingNoticeLink = useCallback(() => {
		sendUIEvent({
			action: 'clicked',
			actionSubject: 'link',
			actionSubjectId: 'cookiesTrackingNotice',
			source: 'cookieConsentBanner',
		});
	}, []);

	// Call onShow/onHide as a side effect of showBanner changing, rather than
	// possibly having to call them in multiple places.
	const prevShowBanner = usePrevious(showBanner);
	useEffect(() => {
		if (showBanner && !prevShowBanner) {
			onShow?.();
		} else if (!showBanner && prevShowBanner) {
			onHide?.();
		}
	}, [showBanner, prevShowBanner, onShow, onHide]);

	if (!showBanner) {
		return null;
	}

	return (
		<FlagsProvider>
			<BSCIntlProvider>
				<PresentationalConsentBanner
					onClickOnlyNecessary={onClickOnlyNecessary}
					onClickAcceptAll={onClickAcceptAll}
					onClickPreferences={onClickPreferences}
					onClickTrackingNoticeLink={onClickTrackingNoticeLink}
					minWidth={minWidth}
				/>
				{isPreferencesModalOpen && (
					<PreferenceModal
						onClose={closePreferenceModal}
						onSubmit={onPreferenceModalSubmit}
						showSuccessFlag={showSuccessFlag}
						showErrorFlag={showErrorFlag}
					/>
				)}
			</BSCIntlProvider>
		</FlagsProvider>
	);
};
