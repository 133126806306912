import React, { useCallback, useEffect, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import {
	sendScreenEvent,
	sendTrackEvent,
	sendUIEvent,
} from '../../../common/utils/analytics-client';
import { Logger } from '../../../common/utils/logger';
import { updatePreferences } from '../../../controllers/storage-preferences/update-preferences';
import type { ConsentDisplayedTextMap } from '../../../services/consent-hub-service/types';
import {
	type ConsentPreference,
	type ConsentPreferences,
	PreferenceCategory,
} from '../../../types';

import { PresentationalPreferenceModal } from './presentational-preference-modal';
interface PreferenceModalProps {
	/**
	 * Callback to run custom onClose logic when the preference modal is closed internally.
	 */
	onClose?: () => void;
	/**
	 * Callback to run custom onSubmit logic when preferences are submitted.
	 */
	onSubmit?: () => void;

	showSuccessFlag?: () => void;
	showErrorFlag?: () => void;
}

export const PreferenceModal = (props: PreferenceModalProps) => {
	// feature flag check
	if (!fg('platform.moonjelly.browser-storage-controls')) {
		return null;
	}

	return <PreferenceModalImpl {...props} />;
};

export const PreferenceModalImpl = ({
	onClose,
	onSubmit,
	showSuccessFlag,
	showErrorFlag,
}: PreferenceModalProps) => {
	const [isModalOpen, setIsModalOpen] = useState(true);

	const isEventSent = useRef(false);
	useEffect(() => {
		if (!isEventSent.current) {
			sendScreenEvent({ name: 'cookieConsentModal' });
			isEventSent.current = true;
		}
	}, [isEventSent]);

	const [preferences, setPreferences] = useState<ConsentPreferences>({
		[PreferenceCategory.StrictlyNecessary]: true,
		[PreferenceCategory.Functional]: false,
		[PreferenceCategory.Marketing]: false,
		[PreferenceCategory.Analytics]: false,
		[PreferenceCategory.Unknown]: false,
	});

	const handleOnSubmit = useCallback(
		async (displayedTextMap: ConsentDisplayedTextMap) => {
			const selectedPreferences: ConsentPreference = preferences;

			sendUIEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'confirm',
				source: 'cookieConsentModal',
			});

			try {
				await updatePreferences(selectedPreferences, displayedTextMap);

				sendTrackEvent({
					source: 'cookieConsentModal',
					action: 'submitted',
					actionSubject: 'cookieConsentPreferences',
				});

				showSuccessFlag?.();
			} catch (e: any) {
				Logger.errorWithOperationalEvent({
					action: 'updatePreferencesError',
					attributes: { preferences: selectedPreferences },
					message: `Failed to update preferences from modal. ${e.message || ''}`,
				});

				showErrorFlag?.();
			}

			setIsModalOpen(false);
			onSubmit?.();
		},
		[preferences, onSubmit, showSuccessFlag, showErrorFlag],
	);

	return (
		<>
			{isModalOpen && (
				<PresentationalPreferenceModal
					setPreferences={setPreferences}
					onSubmit={handleOnSubmit}
					onClose={onClose}
					setIsOpen={setIsModalOpen}
					preferences={preferences}
				/>
			)}
		</>
	);
};
