import React, { type Dispatch, type ReactElement, type SetStateAction, useCallback } from 'react';

import Checkbox from '@atlaskit/checkbox';
import { Box, xcss } from '@atlaskit/primitives';
import { headingSizes as akHeadingSizes } from '@atlaskit/theme/typography';

import { sendUIEvent } from '../../../../../common/utils/analytics-client';
import { PreferenceCategory } from '../../../../../types';
import type { ConsentPreference } from '../../../../../types';

export interface CookieCategorySectionProps {
	isChecked: boolean;
	category: PreferenceCategory;
	titleElement: ReactElement;
	titleString: string;
	descriptionElement: ReactElement;
	setPreferences: Dispatch<SetStateAction<ConsentPreference>>;
}

const tmpTrelloClassNameOverride = 'atl-bsc-preference-modal-checkbox';

// Styles
const categoryBoxStyles = xcss({
	paddingBlockStart: 'space.200',
});

const categoryDescriptionStyles = xcss({
	color: 'color.text.subtle',
	paddingInlineStart: 'space.300',
	marginInlineStart: 'space.050',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${akHeadingSizes.h200.size}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${akHeadingSizes.h200.lineHeight}px`,
});

// We don't bother tracking unknown checkbox toggles
const categoryToEventSubjectMap = {
	[PreferenceCategory.Analytics]: 'performanceConsent',
	[PreferenceCategory.Functional]: 'functionalConsent',
	[PreferenceCategory.Marketing]: 'targetingConsent',
	[PreferenceCategory.Unknown]: null,
};

export const CookieCategorySection = ({
	category,
	titleElement,
	titleString,
	descriptionElement,
	isChecked,
	setPreferences,
}: CookieCategorySectionProps) => {
	const onCheckboxChange = useCallback(() => {
		// Don't alter cookies that are strictly necessary
		if (category === PreferenceCategory.StrictlyNecessary) {
			return;
		}

		setPreferences((prevPreferences) => {
			const isSelected = !prevPreferences[category];

			const actionSubjectId = categoryToEventSubjectMap[category];
			if (actionSubjectId) {
				sendUIEvent({
					actionSubjectId,
					action: 'clicked',
					actionSubject: 'checkbox',
					source: 'cookieConsentModal',
					attributes: { isSelected },
				});
			}
			return {
				...prevPreferences,
				[category]: isSelected,
			};
		});
	}, [category, setPreferences]);

	return (
		<Box xcss={categoryBoxStyles}>
			<Checkbox
				// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-classname-prop
				className={`${tmpTrelloClassNameOverride}`}
				isChecked={isChecked}
				value={isChecked.toString()}
				onChange={onCheckboxChange}
				label={titleElement}
				name={`${titleString}`}
			/>
			<Box xcss={categoryDescriptionStyles}>{descriptionElement}</Box>
		</Box>
	);
};
