import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	preferencesBtn: {
		id: 'browser-storage-controls.consent-banner.preferences.button',
		defaultMessage: 'Preferences',
		description: 'Preferences button that opens consent modal dialog.',
	},
	onlyNecessaryBtn: {
		id: 'browser-storage-controls.consent-banner.only-necessary.button',
		defaultMessage: 'Only necessary',
		description: 'Only necessary button that saves user preferences - only necessary cookies.',
	},
	acceptAllBtn: {
		id: 'browser-storage-controls.consent-banner.accept-all.button',
		defaultMessage: 'Accept all',
		description: 'Accept all button that saves user preferences - accepts all cookies.',
	},
	cookiesTrackingNotice: {
		id: 'browser-storage-controls.consent-banner.cookies-tracking-notice.text',
		defaultMessage: 'Atlassian cookies and Tracking notice',
		description:
			'Link that leads to Atlassian cookies/tracking legal notice text on Atlassian website.',
	},
	useCookiesDescription: {
		id: 'browser-storage-controls.consent-banner.cookies-description.text',
		defaultMessage:
			'Atlassian uses cookies to improve your browsing experience, perform analytics and research, and conduct advertising. Accept all cookies to indicate that you agree to our use of cookies on your device. {linkToCookiesTrackingNotice}',
		description: 'Description that explains Atlassian cookie controls and how it is used.',
	},
	successFlagTitle: {
		id: 'browser-storage-controls.consent-banner.success-flag.title',
		defaultMessage: 'Cookie preferences saved',
		description: 'Title for the success flag shown when cookie preferences are saved initially.',
	},
	successFlagDescription: {
		id: 'browser-storage-controls.consent-banner.success-flag.description',
		defaultMessage: 'You can change this at any time in your Atlassian account.',
		description:
			'Description for the success flag shown when cookie preferences are saved initially.',
	},
	successFlagDescriptionLocalOnly: {
		id: 'browser-storage-controls.consent-banner.success-flag.description-local-only',
		defaultMessage: 'Your preferences will only affect this site.',
		description:
			'Description for the success flag shown when cookie preferences are saved locally, meaning that those preferences will only affect the current domain.',
	},
	errorFlagTitle: {
		id: 'browser-storage-controls.consent-banner.error-flag.title',
		defaultMessage: "We're unable to update the policy",
		description: 'Title for the error flag shown when cookie preferences are not saved.',
	},
	errorFlagDescription: {
		id: 'browser-storage-controls.consent-banner.error-flag.description',
		defaultMessage: 'Try again in a few minutes.',
		description: 'Description for the error flag shown when cookie preferences are not saved.',
	},
});
