import React, {
	type Dispatch,
	type ReactElement,
	type SetStateAction,
	useCallback,
	useMemo,
} from 'react';

import ReactDOMServer from 'react-dom/server';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import Form from '@atlaskit/form';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Anchor, Box, Stack, xcss } from '@atlaskit/primitives';

import { sendUIEvent } from '../../../../common/utils/analytics-client';
import { CONSENT_DISPLAYED_TEXT_ITEMS } from '../../../../services/consent-hub-service/constants';
import type { ConsentDisplayedTextMap } from '../../../../services/consent-hub-service/types';
import { type ConsentPreferences, PreferenceCategory } from '../../../../types';

import { CookieCategorySection } from './cookie-category-section';
import { i18n } from './messages';

const categoryNameStyles = xcss({
	fontWeight: 'bold',
});

interface CategoryCheckboxType {
	category: PreferenceCategory;
	titleElement: ReactElement;
	descriptionElement: ReactElement;
	titleString: string;
}

const getDefaultText = (intl: IntlShape) => {
	const link = intl.formatMessage(i18n.trackingLink);
	const defaultSummaryDescriptionDisplayedText = intl.formatMessage(i18n.summaryDescription, {
		link,
	});
	const defaultStrictDescriptionDisplayedTextNode = intl.formatMessage(i18n.strictDescription, {
		bold: (msg: React.ReactNode[]) => msg,
	});

	// ReactIntl requires interpolated values to be passed in, and this particular string requires a <bold> modifier.
	// Unfortunately, this converts the above formatted message to a ReactNode but ConsentHub needs strings for `displayedText`,
	// thus, we have to render to a static HTML string first and sanitize the result
	const defaultStrictDescriptionDisplayedText = ReactDOMServer.renderToStaticMarkup(
		defaultStrictDescriptionDisplayedTextNode as ReactElement,
	);

	let defaultDisplayedText = `${defaultSummaryDescriptionDisplayedText}. ${defaultStrictDescriptionDisplayedText}`;

	// This outputs an encoded string that jacks up apostrophes, so clean 'em up
	const sanitizedText = defaultDisplayedText.replace(/&#x27;/g, "'");
	return sanitizedText;
};

const getCookieCategories = (
	intl: IntlShape,
): {
	checkboxes: CategoryCheckboxType[];
	displayedTextMap: ConsentDisplayedTextMap;
} => {
	const cookieCategoriesToTranslate = [
		{
			// Tracks Functional related cookies like preferences
			category: PreferenceCategory.Functional,
			title: i18n.functionalOption,
			description: i18n.functionalOptionDescription,
		},
		{
			// Tracks Performance related cookies like analytics
			category: PreferenceCategory.Analytics,
			title: i18n.analyticalOption,
			description: i18n.analyticalOptionDescription,
		},
		{
			// Tracks Targeting related cookies like marketing and advertising
			category: PreferenceCategory.Marketing,
			title: i18n.marketingOption,
			description: i18n.marketingOptionDescription,
		},
	];

	// We'll use this mapping to send the `displayedText` for each checkbox when saving to ConsentHub
	const displayedTextMap = CONSENT_DISPLAYED_TEXT_ITEMS.reduce((translatedTextMap, category) => {
		// Set the description as the default for all items (including items with checkboxes in the UI)
		// and we'll override as needed. Prefs that are not visually displayed in the UI anymore will use this default.
		translatedTextMap[category] = getDefaultText(intl);
		return translatedTextMap;
	}, {} as ConsentDisplayedTextMap);

	const checkboxes = cookieCategoriesToTranslate.map(({ category, title, description }) => {
		const translatedTitle = intl.formatMessage(title);
		const translatedDescription = intl.formatMessage(description);

		// If we are showing individual checkboxes, use that description for auditing purposes
		displayedTextMap[category] = translatedDescription;

		return {
			category,
			titleElement: <Box xcss={categoryNameStyles}>{translatedTitle}</Box>,
			descriptionElement: <Box>{translatedDescription}</Box>,
			titleString: title.defaultMessage,
		};
	});
	return {
		checkboxes,
		displayedTextMap,
	};
};

const noop = () => {};

interface PresentationalPreferenceModalProps {
	setPreferences?: Dispatch<SetStateAction<ConsentPreferences>>;
	onSubmit?: (displayedTextMap: ConsentDisplayedTextMap) => void;
	onClose?: () => void;
	setIsOpen?: (isOpen: boolean) => void;
	preferences: ConsentPreferences;
}

export const PresentationalPreferenceModal = ({
	preferences,
	setPreferences = noop,
	onSubmit = noop,
	onClose = noop,
	setIsOpen = noop,
}: PresentationalPreferenceModalProps) => {
	const intl = useIntl();

	const onCloseModal = useCallback(() => {
		setIsOpen(false);
		onClose();
	}, [setIsOpen, onClose]);

	const onClickCancel = useCallback(() => {
		onCloseModal();

		sendUIEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'cancel',
			source: 'cookieConsentModal',
		});
	}, [onCloseModal]);

	const onClickBackground = useCallback(() => {
		onCloseModal();

		sendUIEvent({
			action: 'clicked',
			actionSubject: 'area',
			actionSubjectId: 'background',
			source: 'cookieConsentModal',
		});
	}, [onCloseModal]);

	const onClickTrackingNoticeLink = useCallback(() => {
		sendUIEvent({
			action: 'clicked',
			actionSubject: 'link',
			actionSubjectId: 'cookiesTrackingNotice',
			source: 'cookieConsentModal',
		});
	}, []);

	const cookieCategories = useMemo(() => {
		return getCookieCategories(intl);
	}, [intl]);

	const handleSubmission = useCallback(() => {
		const { displayedTextMap } = cookieCategories;
		onSubmit(displayedTextMap);
	}, [onSubmit, cookieCategories]);

	return (
		<ModalTransition>
			<Modal onClose={onClickBackground}>
				<Form<ConsentPreferences> onSubmit={handleSubmission}>
					{({ formProps }) => (
						<form {...formProps}>
							<ModalHeader>
								<ModalTitle>
									<FormattedMessage {...i18n.title} />
								</ModalTitle>
							</ModalHeader>
							<ModalBody>
								<Box>
									<FormattedMessage
										{...i18n.summaryDescription}
										values={{
											link: (
												<Anchor
													href="https://www.atlassian.com/legal/cookies#cookies-and-other-tracking-technologies"
													target="_blank"
													onClick={onClickTrackingNoticeLink}
												>
													<FormattedMessage {...i18n.trackingLink} />
												</Anchor>
											),
										}}
										tagName={'p'}
									/>
									<FormattedMessage
										{...i18n.strictDescription}
										values={{
											bold: (msg: React.ReactNode[]) => <strong>{msg}</strong>,
										}}
										tagName={'p'}
									/>
								</Box>
								{cookieCategories.checkboxes.map(
									(
										{ category, titleElement, titleString, descriptionElement: description },
										index,
									) => (
										<Stack key={index}>
											<CookieCategorySection
												category={category}
												titleElement={titleElement}
												titleString={titleString}
												descriptionElement={description}
												isChecked={preferences[category] ?? false}
												setPreferences={setPreferences}
											/>
										</Stack>
									),
								)}
							</ModalBody>
							<ModalFooter>
								<Button appearance="subtle" onClick={onClickCancel}>
									<FormattedMessage {...i18n.cancelBtn} />
								</Button>
								<Button type="submit" appearance="primary" onClick={handleSubmission}>
									<FormattedMessage {...i18n.confirmBtn} />
								</Button>
							</ModalFooter>
						</form>
					)}
				</Form>
			</Modal>
		</ModalTransition>
	);
};
