import { type MutableRefObject, useEffect, useRef, useState } from 'react';

import { handleInitialPreferencesData } from '../../../controllers/cookie-controls/transformer';
import { getPreferences } from '../../../controllers/storage-preferences/get-preferences';
import { updatePreferences } from '../../../controllers/storage-preferences/update-preferences';
import type { PrefetchedConsentPreferences } from '../../../services/consent-hub-service/types';
import { type ConsentPreference, PrefetchedPreferenceState } from '../../../types';

interface PreferencesHookProps {
	isInitialRender: MutableRefObject<boolean>;
	initialPreferences?: PrefetchedConsentPreferences | undefined;
}

/**
 * This hook is responsible for handling any initially provided preference data, and/or fetching missing preference
 * data from ConsentHub. It will then set the appropriate cookies and state for the user's preferences to be used
 * throughout the application.

 * @param (optional) initialPreferences - Any initially provided preferences from consuming applications (used for SSR)
 * @returns preferences - The current state of the user's preferences after fetching/transforming/setting cookies
 */
const usePreferences = ({ isInitialRender, initialPreferences }: PreferencesHookProps) => {
	const prefetchedPreferencesData = useRef(initialPreferences);
	const [preferences, setPreferences] = useState<
		ConsentPreference | PrefetchedPreferenceState | undefined
	>(handleInitialPreferencesData(initialPreferences));

	useEffect(() => {
		// On initial render, this will either set the proper preferences in state + required cookies based on the
		// prefetched data, or fetch all of that data from ConsentHub and then set appropriate cookies + state.
		const loadStoredPrefs = async () => {
			const prefs = await getPreferences();
			// If something has gone wrong during this `get` process (invalid token, failed request, etc.)
			// these preferences will be undefined and we'll need to show the banner as we're in an unknown state
			setPreferences(prefs);

			// Set the initialRender flag to false so we can skip logic in banner rendering
			isInitialRender.current = false;
		};
		const unpackedPrefetchedPrefs = handleInitialPreferencesData(prefetchedPreferencesData.current);

		if (unpackedPrefetchedPrefs !== PrefetchedPreferenceState.INTERNAL) {
			// With a valid set of prefs, set them on the cookies, but don't save to ConsentHub
			updatePreferences(unpackedPrefetchedPrefs, undefined, true);
		}

		loadStoredPrefs();
	}, [isInitialRender]);

	return { preferences };
};

export default usePreferences;
