/* eslint-disable @atlassian/tangerine/import/no-parent-imports */
import { type MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { sendScreenEvent } from '../../../common/utils';
import { isSSR } from '../../../common/utils/is-ssr';
import { ATLASSIAN_SHOW_BANNER_COOKIE_KEY } from '../../../constants';
import { getCookie } from '../../../controllers/cookie-controls/get-cookie';
import type { PrefetchedConsentPreferences } from '../../../services/consent-hub-service/types';
import { type ConsentPreference, PrefetchedPreferenceState } from '../../../types';
import { shouldShowBannerInSSRContext } from '../utils';

interface BannerVisibilityHookProps {
	preferences: PrefetchedPreferenceState | ConsentPreference | undefined;
	initialPreferences?: PrefetchedConsentPreferences;
	isInitialRender: MutableRefObject<boolean>;
}

const calculateBannerStateViaBit = (
	isInitialRender: MutableRefObject<boolean>,
	initialPreferences?: PrefetchedConsentPreferences,
) => {
	// We don't have a great way to handle dealing with consent timestamps right here, as we have no global state/store, so we have to precompute
	// banner visibility state when we fetch consents, and store it in a cookie to be read later, here.
	const shouldShowBannerBit = getCookie(ATLASSIAN_SHOW_BANNER_COOKIE_KEY);

	const inFlowWhereBannerStateIsUnknown =
		initialPreferences && isInitialRender.current && shouldShowBannerBit === undefined;

	if (isSSR() || inFlowWhereBannerStateIsUnknown) {
		// When coming from SSR, this hook will only execute once and won't update later
		// Instead, if we're on the server, have initial preference data with valid consents, don't render
		// On later checks (rehydration), this will always fail and skip to the normal rendering logic

		// Once we're rehydrating, if have been provided initial prefs (from SSR), on the "first render", the banner cookie may still be missing.
		// Due to React lifecycle where this useMemo is executing prior to the useEffect in the usePreferences hook, which sets the core cookies.
		// We should give this flow the same treatment as rendering in an SSR context to match the behavior of the SSR flow to reduce any potential UX flicker.
		return shouldShowBannerInSSRContext(initialPreferences);
	}

	const shouldShowBanner = shouldShowBannerBit ? !!Number(shouldShowBannerBit) : false;

	return shouldShowBanner;
};

/**
 * This hook is responsible for determining whether or not the consent banner should be shown based on the user's
 * preferences and the current state of the banner cookie, as well as if the we're rendering from an SSR context.
 *
 * @param preferences - The current state of the user's preferences
 * @param initialPreferences - Any initially provided preferences from consuming applications (used for SSR)
 * @param isInitialRender - A ref to track if this is the "first" render of the component
 * @returns showBanner - Whether or not the banner should be shown
 * @returns setIsDismissed - Setter function to update the dismissed state of the banner
 */
const useBannerVisibility = ({
	preferences,
	initialPreferences,
	isInitialRender,
}: BannerVisibilityHookProps) => {
	const isEventSent = useRef(false);

	const [isDismissed, setIsDismissed] = useState(false);

	const showBanner = useMemo(() => {
		if (!fg('platform.moonjelly.browser-storage-controls-v2')) {
			// In V1, we only checked based on the existence of the preferences aka missing or "INTERNAL" state
			const prefsMissing = preferences === PrefetchedPreferenceState.INTERNAL || !preferences;
			if (isSSR()) {
				// This logic will execute normally in V2, but for now, as SSR prefetching has not been implemented completely
				// just hide the banner if we're missing the data (always will be in SSR-context) and let the UI handle it on rehydration
				return !prefsMissing && !isDismissed;
			}
			return prefsMissing && !isDismissed;
		}

		const shouldShowBanner = calculateBannerStateViaBit(isInitialRender, initialPreferences);
		// When preferences are unknown and not flagged as internal (e.g. undefined), show the banner
		const unknownPreferences = preferences !== PrefetchedPreferenceState.INTERNAL && !preferences;
		return (unknownPreferences || shouldShowBanner) && !isDismissed;
	}, [preferences, isDismissed, initialPreferences, isInitialRender]);

	useEffect(() => {
		if (!isEventSent.current && showBanner) {
			sendScreenEvent({ name: 'cookieConsentBanner' });
			isEventSent.current = true;
		}
	}, [isEventSent, showBanner]);

	return { showBanner, setIsDismissed };
};

export default useBannerVisibility;
