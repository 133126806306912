import { fg } from '@atlaskit/platform-feature-flags';

import { LEGACY_ATLASSIAN_GDPR_COOKIE_KEY } from '../../../constants';
import type { ConsentDisplayedTextMap } from '../../../services/consent-hub-service/types';
import { type ConsentPreference, PreferenceCategory } from '../../../types';
import { setConsentToken } from '../../cookie-controls/set-consent-token';
import { setStrictlyNecessaryCookie } from '../../cookie-controls/set-strictly-necessary-cookie';
import { packUserPreferences } from '../../cookie-controls/transformer';

const emptyDisplayedTextMap: ConsentDisplayedTextMap = {
	[PreferenceCategory.Analytics]: '',
	[PreferenceCategory.Functional]: '',
	[PreferenceCategory.Marketing]: '',
	[PreferenceCategory.StrictlyNecessary]: '',
	[PreferenceCategory.Unknown]: '',
};

export async function updatePreferences(
	preferences: ConsentPreference,
	displayedTextMap: ConsentDisplayedTextMap = emptyDisplayedTextMap,
	usingPrefetchedData?: boolean,
): Promise<void> {
	if (!fg('platform.moonjelly.browser-storage-controls-v2')) {
		const packedPrefs = packUserPreferences(preferences);
		setStrictlyNecessaryCookie(LEGACY_ATLASSIAN_GDPR_COOKIE_KEY, packedPrefs, {
			expires: 365,
			path: '/',
		});
	} else {
		await setConsentToken(preferences, displayedTextMap, usingPrefetchedData);
	}
}
